(function ($) {
  'use strict';

  Drupal.behaviors.mppSortV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $sortMenu = $('.js-mpp-sort-menu', context);
      var sortFns = {
        price_low_to_high: function (prod) {
          var sku = [];

          if (prod) {
            // Find the sku displayed
            sku = prod.skus.find((skuData) => skuData.IS_DISPLAYED) || prod.defaultSku;

            // Place not displayed products (ex. Sold Out) or without a price at the end.
            return sku.PRICE || 9999;
          }
        },
        price_high_to_low: function (prod) {
          var sku = [];

          if (prod) {
            // Find the sku displayed
            sku = prod.skus.find((skuData) => skuData.IS_DISPLAYED) || prod.defaultSku;

            // _.sortBy() sorts ascending by default
            return sku.PRICE * -1 || 0;
          }
        },
        name_a_z: function (prod) {
          if (!!prod) {
            var name = prod.PROD_RGN_NAME;

            // _.sortBy() sorts uppercase before lowercase
            return name.toLowerCase();
          }
        },
        display_order: 'displayOrder',
        reviews: function (prod) {
          if (!!prod) {
            return prod.AVERAGE_RATING * -1;
          }
        }
      };

      $sortMenu.on('change', function (e) {
        var val = $(this).val();
        var sortFn = sortFns[val];
        var $parentContainer = $(e.target).closest('.js-mpp-container');
        var $grid = $parentContainer.find('.js-product-grid');
        $(document).trigger('mpp_sort:selected', [sortFn, $grid]);
      });
    }
  };
})(jQuery);
